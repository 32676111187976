.navbarScroll {
    background-color: black;
}

.nav-link{
    color: rgb(255, 255, 255);
}

.navbar-brand{
    color: rgb(255, 255, 255);
}