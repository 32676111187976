
.AppOne{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgb(0,252,255);
    background: linear-gradient(47deg, rgba(0,252,255,1) 0%, rgba(235,255,133,1) 100%); 
    width: 100vw;
    height: 100vh;
}

section {
    margin-top: 1%;
}
.cardOne{
    width: 100%;
    height: 100%;
    box-shadow: 0 20px 0 #515134;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.weather{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background-color: gold;
    height: 100%;
    width: 100%;
    padding: 20px 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

}


.weather h1{
    margin: 0;
    font-size: 3em;
  }
  .location{
    color: #fff;
  }
  .city{
    margin: 0;
    font-size: 2em;
  }
  
  .state{
    margin-top: 0;
  }
  
  .info{
    display: flex;
    background-color: #fff;
    width: 100%;
    white-space: nowrap;
  }
  
  .info *{
    padding: 20px;
    flex: 1;
    border-right:1px solid #e6e6e6;
  }